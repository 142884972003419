<template>
  <div class="Speaking">
    <el-card class="work relative" v-if="source">
      <div slot="header" class="flex flex-between flex-v-center color-white">
        <el-page-header @back="goBack" title="">
          <div slot="content" class="flex flex-v-center color-white">
            <div class="flex flex-v-center">
              <div class="color-white">{{ source_index + 1 }}/{{ total }}</div>
              <div class="margin-l">{{ $t(info.title.replace("-", "")) }}</div>
              <el-divider direction="vertical">

              </el-divider>
              <h4 class="bold">{{ source.title }}</h4>
              <div class="flex">
                <el-tag size="small" type="success" class="margin-ls">#{{ source.no }}</el-tag>
                <el-tag size="small" v-if="source.is_gmat" class="margin-ls" type="success">机经</el-tag>
              </div>
            </div>

          </div>
        </el-page-header>


        <div class="flex">
          <el-tooltip v-if="source" class="pointer" effect="light"
            :content="source.exams_count > 0 ? ($t('考过') + '(' + source.exams_count + ')') : $t('考过')" placement="top">
            <i class="el-icon-s-flag icon" @click="dialogVisible = true"></i>
          </el-tooltip>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('报错')" placement="top">
            <i class="el-icon-info icon" @click="review"></i>
          </el-tooltip>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('分享')" placement="top">
            <i class="el-icon-share icon" @click="share"></i>
          </el-tooltip>

          <el-tooltip class="margin-l pointer" effect="light" :content="$t('收藏')" placement="top">
            <div slot="content" class="flex flex-v-center">
              <el-rate v-model="stars" @change="addFavorite"></el-rate>
              <el-divider v-if="stars > 0" direction="vertical"></el-divider>
              <el-link v-if="stars > 0" @click="removeFavorite" type="primary">{{ $t('重置') }}</el-link>
            </div>

            <div class="flex flex-v-center">
              <i class="icon" :class="stars > 0 ? 'el-icon-star-on' : 'el-icon-star-off'"></i>
              <span v-if="stars" style="font-size:14px;">({{ stars + $t('星') }})</span>
            </div>

          </el-tooltip>

          <el-dropdown @command="addMineFavorite" class="margin-l" slot="content" placement="bottom">
            <i class="icon el-icon-plus" style="color:#ffffff;"></i>
            <el-dropdown-menu>
              <el-dropdown-item :command="item.id" v-for="(item, index) in favorites" :key="index">
                <div class="flex flex-v-center flex-h-center"
                  :class="{ 'st bold': source.mine && source.mine.favorite_id == item.id }">
                  {{
                    item.title
                  }}</div>
              </el-dropdown-item>
              <el-dropdown-item command="0">
                <div class="flex flex-v-center st bold">
                  <i class="el-icon-plus"></i>
                  {{ $t('创建收藏夹') }}
                </div>
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div class="margin-x" v-if="info.description">
        <div class="mt bold tj desc"><strong><em>{{ info.description }}</em></strong></div>
      </div>

      <div class="flex flex-v-center" style="justify-content: flex-end;position: absolute;right:0;right:20px">
        <span>{{ currentIndex + 1 }}/{{ audio_langs.length + 2 }}</span>
        <div style="width:200px" class="margin-x">
          <el-progress :show-text="false"
            :percentage="(currentIndex + 1) / (audio_langs.length + 2) * 100"></el-progress>
        </div>

        <div class="timer">
          <i class="el-icon-time" style="color:#000;font-weight: 900;"></i>
          {{ format_time(timer) }}
        </div>
      </div>
      <div>
        <div class="main" v-if="source.words_en">
          <el-carousel ref="carousel" :loop="false" arrow="never" :interval="4000" trigger="click" :autoplay="false"
            height="600px">
            <el-carousel-item>
              <div class="infomation">
                <p style="font-size: 13px;font-weight: 400;color: #535658;">INFORMATION 1</p>
                <!-- <p class="margin-lt"><i class="el-icon-question" style="color:gray;"></i></p> -->
                <p class="margin-lt" style="color: #e6a13c;font-weight: 700;">INFORMATION FOR CANDIDATES</p>
                <p class="margin-lt">The Practice Test includes 1 dialogue only (there will be 2 dialogues in your
                  actual
                  CCL Test)</p>
                <p class="margin-lt">There are a number of segments in the dialogue which should be rendered from the
                  source language to the target language.</p>
                <p class="margin-ts">If you would like to repeat a segment, click on <strong>Finish attempt</strong>,
                  and
                  the <strong>Start</strong> button again.</p>
                <p class="margin-ts">You are permitted <strong>one segment repeat per dialogue without penalty.</strong>
                </p>
                <p class="margin-lt">Please click <strong>Next</strong> <span class="icon-next"><i
                      class="el-icon-right"></i></span> to start with the <strong>example
                    segment.</strong></p>
              </div>
            </el-carousel-item>

            <el-carousel-item>
              <div class="flex flex-between flex-v-center">
                <div class="infomation flex1">
                  <p style="font-size: 13px;font-weight: 400;color:  #535658;;">INFORMATION 2</p>

                  <p class="margin-lt" style="color: #e6a13c;font-weight: 700;">EXAMPLE SEGMENT</p>

                  <p class="margin-lt">This segment is intended to <strong>test your audio and visual equipment</strong>
                    prior to taking the full CCL test. </p>

                  <p class="margin-ts">Click on <strong>Start</strong> below the audio, to hear the segment and test
                    your
                    microphone and camera.</p>
                  <p class="margin-ts">You should be able to see yourself, and notice soundwaves when you start speaking
                    after the chime.</p>
                  <p class="margin-ts">Click on <strong>Finish attempt</strong>, to upload.
                  </p>
                  <p class="margin-ts">Click the <strong>Play</strong> button after you have spoken and listen back to
                    the
                    audio to ensure your voice has been recorded.</p>
                  <p class="margin-ts">(the Play button is only available on the example segment to test your
                    microphone)
                  </p>

                  <p class="margin-lt">
                    <strong>Repeat:</strong>
                  </p>
                  <p class="margin-ts">
                    You are able to repeat any segment before you begin speaking (one repeat per dialogue without
                    penalty)
                    to do this:
                  </p>
                  <p class="margin-ts"><span class="marker"></span>Click on the <strong>Start</strong> button again</p>

                  <p class="margin-lt">Please click <strong>Next</strong> <span class="icon-next"><i
                        class="el-icon-right"></i></span> to move on</p>
                </div>
                <div class="flex1">
                  <ccl-player :webcam="false" ref="player1" no="example"></ccl-player>
                </div>
              </div>
            </el-carousel-item>



            <el-carousel-item class="row" v-for="(item, index) in audio_langs" :key="index">
              <div class="flex flex-between flex-v-center">
                <div class="infomation">
                  <p style="font-size: 13px;font-weight: 400;color:  #535658;;">SEGMENT {{ index + 1 }}</p>

                  <p class="margin-lt" style="color: #e6a13c;font-weight: 700;">Mandarin CCL test</p>

                  <p class="margin-lt"><strong>To hear the segment</strong> </p>

                  <p class="margin-lt"><span class="marker"></span>Click <span class="start">Start</span> and speak
                    after
                    the chime</p>

                  <p class="margin-t"><span class="marker"></span>Click <span class="btn-finish">Finish attempt</span>
                    to upload</p>

                  <p class="margin-lt"><strong>To repeat a segment</strong> </p>
                  <p class="margin-t">(One per dialogue without penalty)</p>
                  <p class="margin-lt"><span class="marker"></span>Click <span class="start">Start</span> and speak
                    after
                    the chime</p>
                </div>

                <div style="flex: 1;margin-left: 40px;">
                  <ccl-player v-if="max_step > index" :webcam="index == 0 ? false : false" :lang="item"
                    :ref="'player' + (index + 2)" :no="'ccl_' + source.no + '_' + index"></ccl-player>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <div class="flex flex-h-center flex-v-center">
            <div @click="carousel(-1)" class="pre" :class="{ 'disabled': currentIndex == 0 || isRecording }"><i
                class="el-icon-back"></i>Previous</div>
            <div class="indicator" :class="{ 'on': currentIndex >= index - 1 }" v-for="index in audio_langs.length + 2"
              :key="index">{{
                index }}</div>
            <div @click="carousel(1)" class="next"
              :class="{ 'disabled': currentIndex == audio_langs.length + 1 || isRecording }">Next <i
                class="el-icon-right"></i></div>
          </div>
        </div>

        <div class="flex flex-v-center flex-between margin-lt">
          <div class="flex flex-v-center">
            <el-button size="small" class="margin-l" type="warning" icon="el-icon-notebook-2"
              @click="$refs.wordsPanel.show()">{{ $t('中英对照') }}
            </el-button>


            <el-button size="small" class="margin-l" type="danger" icon="el-icon-video-camera" @click="showVideo()">{{
              $t('教师讲解') }}
            </el-button>

          </div>
          <div class="flex flex-v-center">
            <el-tooltip class="item" effect="dark" :content="random ? $t('随机模式') : $t('顺序模式')" placement="top">
              <el-image class="icon-player pointer margin-x" @click="setRandom()"
                :src="'/web/image/Speaking' + (random ? '/random.png' : '/order.png')"></el-image>
            </el-tooltip>

            <!-- <el-checkbox v-model="random" class="margin-x st1">{{ random? '随机模式': '顺序模式' }}</el-checkbox> -->
            <el-button size="small" type="warning" @click="reDo" icon="el-icon-refresh-right">{{ $t('重做') }}</el-button>
            <el-button size="small" icon="el-icon-top" type="info" @click="pre()"
              :disabled="source_index == 0 && page == 1">{{ $t('上一题') }}</el-button>
            <el-button size="small" icon="el-icon-bottom" type="info" @click="next()"
              :disabled="source_index == total - 1">{{ $t('下一题') }}
            </el-button>

            <el-button size="small" type="danger" icon="el-icon-position" :disabled="submitCheck()"
              @click="submitRecord" class="margin-l">{{ $t(submitText) }}
            </el-button>
          </div>
        </div>
      </div>
      <div v-if="activeName == '1'" style="position: relative;top:40px;float:right;right:20px;z-index: 100;"
        class="right"><el-switch v-model="record_flag" @change="query_record_list(1)" :active-value="0"
          :inactive-value="1" :active-text="$t('所有人')" active-color="#E6A23C" :inactive-text="$t('仅自己')"></el-switch>
      </div>
      <el-tabs v-model="activeName" class="margin-lt margin-x">

        <el-tab-pane name="1">
          <span slot="label"> {{ $t('练习') }} <span class="st">{{ record_total }} </span> </span>
          <div class="padding">

            <record-list v-if="activeName == '1' && source" :list="recordList" :answer="source.answer" :tag="source.tag"
              type="ccl"></record-list>
            <div class="center margin-t">
              <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper"
                :total="record_total">
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2" v-if="$store.state.user">
          <span slot="label"> {{ $t('互动') }} <span class="st">{{ comment_total }}</span></span>

          <comment v-if="$store.state.user" :commentList="commentList" :commentNum="comment_total"
            :avatar="$store.state.user.avatar.indexOf('http') == 0 ? $store.state.user.avatar : $host + $store.state.user.avatar"
            :authorId="-1" @doSend="sendComment" @doChidSend="sendChidComment"></comment>

          <div class="center margin-t">
            <el-pagination @current-change="pageChange" background layout="prev, pager, next, jumper"
              :total="comment_total">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="$store.getters.typeSettings(source.tag)[0] && (($store.getters.typeSettings(source.tag)[1] && source.answer) || ($store.getters.typeSettings(source.tag)[2] && source.analysis))"
          :label="$store.getters.typeSettings(source.tag)[0]" name="3">
          <div style="line-height:20px" class="mt ls1"
            v-if="$store.getters.typeSettings(source.tag)[1] && source.answer">
            <div
              v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(source.tag)[1] + ':</h4>' + row : row ? row : '&nbsp;'"
              v-for="(row, index) in source.answer.split('\n')" :key="index">
            </div>
          </div>
          <el-divider
            v-if="$store.getters.typeSettings(source.tag)[1] && source.answer && $store.getters.typeSettings(source.tag)[2] && source.analysis"></el-divider>
          <div class="margin-t mt ls1" style="line-height:20px"
            v-if="$store.getters.typeSettings(source.tag)[2] && source.analysis">
            <div v-for="(row, index) in source.analysis.split('\n')"
              v-html="index == 0 ? '<h4>' + $store.getters.typeSettings(source.tag)[2] + '：</h4><div>' + row + '</div>' : row ? row : '&nbsp;'"
              :key="index">
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>


    <el-card v-else>
      <el-page-header class="color-white" slot="header" @back="goBack">
        <div slot="content" class="color-white">{{ tag.indexOf('star') == 0 ? $t('我的收藏')
          : (title ? $t(title) : $t(info.title.replace("-", ""))) }}
        </div>
      </el-page-header>
      <el-empty></el-empty>
    </el-card>


    <div v-if="!no" class="drawer-btn" :class="drawer ? 'open' : ''" @click="drawer = !drawer"><i
        :class="drawer ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></i></div>
    <el-drawer :visible.sync="drawer" size="65%" :with-header="false">
      <div class="drawer">
        <div class="flex flex-between flex-v-center margin-lt">
          <h3 class="lt">{{ title ? $t(title) : $t(info.title.replace("-", "")) }}</h3>
          <el-input clearable v-model="filterData.search" @change="query(1)"
            style="width:300px;background-color: #ccc; border-radius: 20px;" :placeholder="$t('请输入题目/编号/关键词')"
            suffix-icon="el-icon-search">
          </el-input>
        </div>
        <el-tag class="margin-t mt tip" v-if="info.tips" style="background-color: #e6a13c;" effect="dark"><span
            class="bold mt">TIPS：</span><span>{{
              info.tips
            }}</span></el-tag>
        <div class="menus">
          <el-menu v-if="tag == 'mock'" mode="horizontal" :default-active="mock_tag" @select="changeTag">
            <el-menu-item :index="type.tag" class="mt" v-for="(type, index) in $store.state.cclTypes" :key="index"><span
                class="mt1">{{ type.title.split("-")[0] }}</span></el-menu-item>
          </el-menu>
          <el-menu v-else mode="horizontal" :default-active="tag" @select="changeTag">
            <el-menu-item :index="type.tag" class="mt" v-for="(type, index) in $store.state.cclTypes" :key="index"><span
                class="mt1">{{ type.title.split("-")[0] }}</span></el-menu-item>
          </el-menu>
          <div class="line"></div>
          <div class="flex margin-t">
            <el-select @change="query(1)" clearable v-model="filterData.times" size="small" class="margin-x select"
              :placeholder="$t('练习次数')">
              <el-option value="不限">{{ $t('不限') }}</el-option>
              <el-option value="未练习">{{ $t('未练习') }}</el-option>
              <el-option value="少于2次">{{ $t('少于2次') }}</el-option>
            </el-select>
            <el-select v-if="tag && tag.indexOf('star') == -1" @change="query(1)" clearable v-model="filterData.stars"
              size="small" class="margin-x select" :placeholder="$t('收藏')">
              <el-option :value="index" v-for="index in 5" :label="index + $t('星')" :key="index"><i
                  v-for="index1 in index" style="color:rgb(247, 186, 42)" :key="index1"
                  class="el-icon-star-on"></i></el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="drawer">
        <div class="mt padding flex flex-between flex-v-center">
          <span> {{ $t('题库共') }}{{ allCount }}{{ $t('道题') }}，{{ $t('筛选出') }}{{ total }}{{ $t('道题') }}</span>

          <el-button style="font-size:20px;font-weight: bold;" icon="el-icon-refresh-right" @click="reset" size="small"
            circle type="primary"></el-button>
        </div>
        <div v-loading="loading0">
          <div class="pointer" v-for="(item, index) in list" :key="index" @click="choose(index)">
            <div class="flex flex-between padding-s">
              <div class="flex flex-v-center st1" :class="source_index % 10 == index ? 'on' : ''">

                <div>{{ (page - 1) * 10 + index + 1 }}</div>
                <div class="margin-l">{{ item.title }}</div>

                <div class="flex">
                  <el-tag type="primary" effect="dark" size="small" class="margin-ls">#{{ item.no }}</el-tag>
                  <el-tag effect="dark" size="small" v-if="item.frequency == 1" class="margin-ls" type="primary">{{
                    $t('高频') }}</el-tag>
                  <el-tag effect="dark" size="small" v-if="item.is_gmat" class="margin-ls" type="primary">{{ $t('机经')
                    }}</el-tag>
                  <el-tag effect="dark" size="small" v-if="item.is_new" class="margin-ls" type="primary">{{ $t('新题')
                    }}</el-tag>
                </div>
              </div>

              <div class="flex flex-v-center">
                <div class="margin-l" v-if="item.favorite">
                  <i v-for="index1 in item.favorite.stars" style="color:rgb(247, 186, 42)" :key="index1"
                    class="el-icon-star-on"></i>
                </div>
                <el-tag v-if="item.records_count" type="success" class="margin-l">{{ $t('已练') }}{{ item.records_count
                  }}{{ $t('次') }}</el-tag>
                <el-tag v-else type="success" class="margin-l">{{ $t('未练习') }}</el-tag>

              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>

        <div class="center margin-t">
          <el-pagination :page-size="20" :current-page.sync="page" @current-change="pageChange0" background
            layout="total,prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </el-drawer>


    <words-resize-panel v-if="source" ref="wordsPanel" type="ccl" :source="source">
    </words-resize-panel>








    <el-dialog title="" :visible.sync="dialogVisible" top="15%" width="500px" center>
      <h4 slot="title">{{ $t('添加您的考试信息') }}</h4>

      <el-tabs v-model="activeName1">
        <el-tab-pane :label="$t('考试信息')" name="1">
          <el-form ref="examForm" size="small" :model="examForm" label-width="100px">
            <el-form-item prop="date" :label="$t('考试日期') + ':'"
              :rules="[{ required: true, message: '请选择考试日期', trigger: 'blur' },]">
              <el-date-picker value-format="yyyy-MM-dd" v-model="examForm.date" type="date" :placeholder="$t('选择日期')">
              </el-date-picker>
            </el-form-item>

            <el-form-item :label="$t('考试回忆') + ':'">
              <el-input type="textarea" :rows="3" v-model="examForm.content" :placeholder="$t('请输入您的考试回忆')"></el-input>
            </el-form-item>
          </el-form>

          <div class="center">
            <el-button round style="width:200px" type="primary" @click="examedSubmit">{{ $t('提交') }}</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('我的记录')" name="2">
          <el-table :data="examList" style="width: 100%">
            <el-table-column prop="date" :label="$t('考试日期')">
            </el-table-column>
            <el-table-column prop="content" :label="$t('考试回忆')">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <word-dialog ref="wordDialog" :type="1"></word-dialog>
    <share-dialog ref="shareDialog"></share-dialog>
    <review-dialog ref="reviewDialog"></review-dialog>

    <el-dialog title="" :visible.sync="dialogVisible1" width="600px" center>
      <h4 slot="title">{{ $t('教师讲解') }}</h4>
      <video-player class="video-player" ref="videoPlayer" :options="playerOptions">
      </video-player>
    </el-dialog>

    <audio ref="audio" @ended="audioEnd()" src="/audio/ccl.mp3"></audio>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import comment from '../../components/comment/Comment.vue'
import recordList from '../../components/RecordList.vue'
import wordDialog from '../../components/WordDialog.vue'
import shareDialog from '../../components/ShareDialog.vue'
import reviewDialog from '../../components/ReviewDialog.vue'
import { videoPlayer } from "vue-video-player";
// import WavePlayer from '../../components/WavePlayer.vue'
import drag from "../../components/drag";
import wordsResizePanel from '../../components/WordsResizePanel.vue'

import CclPlayer from '../../components/CclPlayer.vue'
// import AudioRecorder from '../../components/AudioRecorder.vue'
import 'video.js/dist/video-js.css'
export default {
  directives: {
    drag
  },
  components: {
    videoPlayer, CclPlayer, wordsResizePanel, recordList, comment, wordDialog, shareDialog, reviewDialog
  },

  data() {
    return {
      title: "",
      isStarted: false,
      isRecording: false,
      loading0: false,
      random: false,
      stars: 0,
      drawer: true,
      filterData: {
        tag: "",
        sort: "",
        times: "",
        search: "",
      },
      page: 1,
      source: { tag: '', words_en: "", words_zh: "", stars: 0, exams_count: 0 },
      activeName: "0",
      activeName1: "1",
      timer: 1200,
      timerJob: null,
      tag: '',
      mock_tag: '',
      no: '',
      type: 1,
      list: [],
      total: 0,
      allCount: 0,
      recordCount: 0,

      dialogWordsVisible: false,
      dialogWordsVisible1: false,
      reviewList: [],

      comment_page: 1,
      comment_total: 0,
      commentList: [],

      examList: [],

      record_page: 1,
      recordList: [],
      record_flag: 1,
      record_total: 0,

      content: [],

      examForm: {
        date: "",
        content: ""
      },
      dialogVisible: false,
      source_index: 0,
      submitText: "提交",
      currentIndex: 0,
      dialogVisible1: false,
      audio_langs: [],
      playerOptions: {
        playbackRates: [1.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      mock: 0,
      max_step: 0,
      favorites: []
    }
  },
  computed: {
    ...mapState(['cclTypes']),

    info: function () {
      let info = null;
      if (this.$route.query.tag == "mock") {
        info = { "title": "模考题" };
      } else {
        info = this.$store.getters.getCclType(this.tag) || { title: '' };
      }
      return info;
    }
  },

  watch: {
    $route(route) {
      this.tag = route.query.tag || "";
      if (this.tag) {
        this.no = "";
        this.drawer = true;
      }
      if (this.tag == 'mock' && route.hash) {
        this.mock_tag = route.hash.replace("#", "");
      }
      this.page = 1;
      this.source_index = 0;
      this.query();
    },

    source_index(val) {
      if (this.tag) {
        localStorage.setItem(this.tag + "_source_index", val)
      }
    },

    source(val, oldVal) {
      if (val && (val != oldVal || val.id != oldVal.id)) {
        this.content = [];
        this.stars = val.favorite ? val.favorite.stars : 0;
        this.setLangs();

        this.query_record_list();
        this.query_comment_list();
        this.query_exam_list();
        this.timer = 1200;
        this.timeJob();

        this.submitText = "提交";
        this.activeName = "0";
        this.isStarted = false;

        this.currentIndex = 0;
        this.max_step = 0;

        if (this.$refs.wordsPanel) {
          this.$refs.wordsPanel.hide()
        }
      }
    },
  },

  created() {
    if (this.$route.query.tag == "mock") {
      this.filterData.tag = "模考";
      if (this.$route.hash) {
        this.mock_tag = this.$route.hash.replace("#", "");
      }
    }
    this.$store.dispatch("fetch_ccl_types");
    this.$bus.$emit("aside", false);
    this.$bus.$on("word", (word) => {
      word = word.replace(/(^\W*)|(\W*$)/g, "");
      word = word.trim().toLowerCase();
      if (word) {
        this.$refs.wordDialog.show(word, this.source.tag);
      }
    })

    this.$bus.$on("content", (content) => {
      if (this.currentIndex > 1) {
        this.content[this.currentIndex - 2] = content;
      }
    })

    this.$bus.$on("started", (flag) => {
      this.isRecording = flag;
    })


    this.tag = this.$route.query.tag;
    this.no = this.$route.query.no || "";

    if (this.no) {
      this.drawer = false;
    }

    if (this.tag) {
      let source_index = localStorage.getItem(this.tag + "_source_index");
      if (source_index != undefined) {
        this.source_index = parseInt(source_index);
        let last_param = localStorage.getItem(this.tag + "_last_param");
        if (last_param) {
          let param = JSON.parse(last_param);
          if (this.tag == 'mock') {
            this.page = param.page;
            this.type = param.type;
            this.no = param.no;
            this.filterData = param.filter;
            this.filterData.tag = "模考";
          } else {
            this.page = param.page;
            this.tag = param.tag;
            this.type = param.type;
            this.no = param.no;
            this.filterData = param.filter;
          }
        }
      }
      this.query();
      this.query_favorites();
    }
  },

  destroyed() {
    if (this.timerJob) {
      clearInterval(this.timerJob);
    }
  },

  mounted() {
    this.keyDown();
  },


  beforeDestroy() {
    this.keyDownReview()
  },


  methods: {
    addMineFavorite(id) {

      if (id == 0) {
        if (this.$store.getters.getRole() > 0) {
          this.$prompt('', this.$t('创建收藏夹'), {
            inputValue: '',
            center: true,
            roundButton: true,
            confirmButtonText: this.$t('确定'),
            cancelButtonText: this.$t('取消'),
          }).then(({ value }) => {
            if (value) {
              this.$http.post("/api/source/mine/favorite", { id: 0, title: value }).then((res) => {
                this.addMineFavorite(res.id)
                this.query_favorites();
              })
            }
          }).catch(() => {

          });
        } else {
          this.$vip_alert("添加单词本");
        }
      } else {
        this.$http.post("/api/source/mine", { favorite_id: id, source_id: this.source.id }).then(() => {
          if (this.source.mine) {
            this.source.mine.favorite_id = id;
          } else {
            this.source.mine = { favorite_id: id }
          }
          this.$message.success(this.$t("添加成功"))
        })
      }
    },
    query_favorites() {
      this.$http.post("/api/source/mine/favorite/list").then((favorites) => {
        this.favorites = favorites;
      })
    },
    format_time(time) {
      if (time < 60) {
        return "00:" + (time > 9 ? time : ("0" + time));
      } else {
        let m = parseInt(time / 60);
        let s = time % 60;
        return (m > 9 ? m : '0' + m) + ":" + (s > 9 ? s : '0' + s)
      }
    },

    keyDownReview() {
      document.onkeydown = function (event) {
        var e = event || window.event;
        e.returnValue = true;
      }
    },

    keyDown() {
      document.onkeydown = this.keyEvent;
    },

    keyEvent(event) {
      if (this.currentIndex > 0) {
        var e = event || window.event;
        var keyCode = e.keyCode || e.which;
        let player = this.$refs['player' + this.currentIndex][0] || this.$refs['player' + this.currentIndex];
        switch (keyCode) {
          case 32:
            player.keyClick();
            break;
        }
      }
    },

    carousel(index) {
      if (this.isRecording || (this.currentIndex == 0 && index < 0) || (this.currentIndex == this.audio_langs.length + 1 && index > 0)) return;

      if (this.timer <= 0) {
        this.$alert('Thank you for completing the NAATI CCL Practice test. ', 'Session finished', {
          type: "success",
          confirmButtonText: 'OK',
        });
      }

      if (this.timer > 0 && !this.isStarted && this.currentIndex == 1 && index == 1) {
        this.$alert('That is the end of the introduction. The test will begin now.', 'Start', {
          type: "warning",
          confirmButtonText: 'OK',
        });
        this.isStarted = true;
      }

      if (this.currentIndex >= 1 && this.timer > 0) {
        let player = this.$refs['player' + this.currentIndex][0] || this.$refs['player' + this.currentIndex]
        player.stop();
        // player.closeWebcam();
      }
      this.currentIndex += index;
      if (this.currentIndex >= 1 && this.timer > 0) {
        let player = this.$refs['player' + this.currentIndex][0] || this.$refs['player' + this.currentIndex]
        player.load();
        // player.openWebcam();
      }
      if (this.currentIndex > this.max_step) {
        this.max_step = this.currentIndex;
      }
      this.$refs.carousel.setActiveItem(this.currentIndex);
    },
    setLangs() {
      let audio_langs = [];
      let reg = new RegExp("[\\u4E00-\\u9FFF]+", "g")
      this.source.words_en.split('\n').forEach((row) => {
        if (reg.test(row)) {
          audio_langs.push("zh")
        } else {
          audio_langs.push("en")
        }
      })
      this.audio_langs = audio_langs;
    },

    showVideo() {
      this.dialogVisible1 = true;
    },
    submitCheck() {
      return this.isRecording || this.content == null || this.content.length == 0 || this.submitText != '提交';
    },
    setRandom() {
      this.random = !this.random;
      this.query();
    },


    pageChange0(page) {
      this.page = page;
      this.source_index = (this.page - 1) * 10;
      this.query();
    },




    audioEnd() {
      this.$refs["recorder" + this.currentIndex][0].start();
    },
    reset() {
      this.$confirm('是否重置练习记录?', '提示', {
        // center: true,
        customClass: "reset",
        confirmButtonText: '确定',
        confirmButtonClass: "confirm-btn",
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/source/record/clear", { tag: this.tag }).then(() => {
          this.$message({
            type: 'success',
            message: '重置成功!'
          });

          this.list.forEach(item => {
            item.records_count = 0;
          })
        })
      }).catch(() => {

      });
    },

    pageChange(page) {
      this.query_record_list(page);
    },

    review() {
      this.$refs.reviewDialog.show(this.source.id);
    },

    share() {
      this.$refs.shareDialog.show("分享题目", window.location.href + "&id=" + this.source.id, "复制链接，向小伙伴分享此题目！");
    },

    changeTag(index) {

      if (this.tag.indexOf("favorite") === 0) {
        this.filterData.tag = index;
        this.query(1)
      } else {
        if (this.$route.query.tag == "mock") {
          this.$router.replace("/main/ccl?tag=mock#" + index)
        } else {
          this.$router.replace("/main/ccl?tag=" + index)
        }
      }

    },

    addFavorite(stars) {
      this.$http.post("/api/source/favorite", { source_id: this.source.id, stars: stars }).then((info) => {
        this.source.favorite = info;
        this.$message({
          message: '收藏成功',
          type: 'success'
        });
      });
    },

    removeFavorite() {
      this.$http.post("/api/source/favorite/remove", { source_id: this.source.id }).then(() => {
        this.source.favorite = null;
        this.stars = 0;
      });
    },


    submitRecord() {
      if (this.submitText != "提交") return;
      // this.submitText = "提交中...";

      this.$nextTick(() => {
        let formData = new FormData();
        let indexs = [];
        this.content.forEach((item, index) => {
          if (item) {
            indexs.push(index);
            formData.append("file" + index, item);
          }
        })
        formData.append("indexs", indexs);
        formData.append("source_id", this.source.id);

        this.$http.post("/api/ccl/record", formData, {
          'Content-type': 'multipart/form-data'
        }).then(() => {
          this.submitText = "已提交"
          this.$message({
            message: '提交成功',
            type: 'success'
          });
          this.query_record_list();
        });
      })
    },


    timeJob() {
      if (this.timerJob) {
        clearInterval(this.timerJob);
      }

      this.timerJob = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.timerJob);
          this.timerJob = null;
          this.$alert('Thank you for completing the NAATI CCL Practice test. ', 'Session finished', {
            type: "success",
            confirmButtonText: 'OK',
          });
        } else {
          this.timer--
        }
      }, 1000)
    },

    personal() {
      this.$confirm('请先完善您的用户信息', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push("/main/personal")
      }).catch(() => {

      });
    },

    sendComment(content) {
      if (!this.$store.state.user.nickName) {
        this.personal();
      } else {
        this.$http.post("/api/source/comment", { source_id: this.source.id, content: content }).then(() => {
          this.query_comment_list();
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        })
      }
    },

    sendChidComment(content, target_id, parent_id) {

      if (!this.$store.state.user.nickName) {
        this.personal();
      } else {
        this.$http.post("/api/source/comment", { source_id: this.source.id, parent_id: parent_id, target_id: target_id, content: content }).then(() => {
          this.query_comment_list();
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        })
      }
    },

    query_review_list() {
      this.$http.post("/api/review/list", { source_id: this.source.id }).then((list) => {
        this.reviewList = list;
      })
    },


    query_exam_list() {
      this.$http.post("/api/source/exam/list", { source_id: this.source.id }).then((list) => {
        this.examList = list;
      })
    },

    query_comment_list(page) {
      this.comment_page = page;
      this.$http.post("/api/comment/list", { source_id: this.source.id, page: this.comment_page }).then((res) => {
        this.comment_total = res.total;
        this.commentList = res.data;
      })
    },

    query_record_list(page) {
      this.record_page = page;
      this.$http.post("/api/record/list", { source_id: this.source.id, flag: this.record_flag, page: this.record_page }).then((res) => {
        let list = res.data;
        list.forEach(item => {
          item.content = JSON.parse(item.content);
          let audit_content = {};
          if (item.audit_content) {
            let items = JSON.parse(item.audit_content);
            items.forEach((item1) => {
              audit_content[item1.index] = { url: item1.url, text: item1.text };
            })
          }
          item.audit_content = audit_content;

          if (item.items) {
            item.items.forEach((item1) => {
              item1.content = JSON.parse(item1.content);
              let audit_content = {};
              if (item1.audit_content) {
                let items = JSON.parse(item1.audit_content);
                items.forEach((item2) => {
                  audit_content[item2.index] = { url: item2.url, text: item2.text };
                })
              }
              item1.audit_content = audit_content;
            })
          }

        });
        this.recordList = list;
        this.record_total = res.total;
      })
    },



    examedSubmit() {
      this.$refs.examForm.validate((valid) => {
        if (valid) {
          this.examForm["source_id"] = this.source.id;
          this.$http.post("/api/source/exam", this.examForm).then((count) => {
            this.source.exams_count = count;
            this.dialogVisible = false;
            this.$message({
              message: '提交成功，感谢您的分享！',
              type: 'success'
            });
            this.query_exam_list();
          })
        }
      });
    },

    reDo() {
      this.submitText = "提交";
      this.isStarted = false;

      if (this.currentIndex >= 1) {
        let player = this.$refs['player' + this.currentIndex][0] || this.$refs['player' + this.currentIndex]
        player.stopPlay();
        player.closeWebcam();
      }
      this.currentIndex = 0;
      this.$refs.carousel.setActiveItem(this.currentIndex);
    },


    goBack() {
      this.$router.replace("/main");
    },

    setSource() {
      this.source = this.list[this.source_index % 10];
    },

    choose(index) {
      this.source_index = (this.page - 1) * 10 + index;
      this.source = this.list[index];
      this.drawer = false;
    },

    random_index(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    next() {
      this.source_index++;

      if (this.source_index == this.page * 10) {
        this.page++;
        this.query();
      } else {
        this.setSource();
      }
    },

    pre() {
      this.source_index--;
      if (this.source_index == (this.page - 1) * 10 - 1) {
        this.page--;
        this.query();
      } else {
        this.setSource();
      }
    },

    query(page) {
      this.loading0 = true;
      if (page) this.page = page;
      let param = { page: this.page, tag: this.tag, mock_tag: this.mock_tag, type: this.type, no: this.no, filter: this.filterData };

      if (this.tag) {
        localStorage.setItem(this.tag + "_last_param", JSON.stringify(param));
      }

      param["random"] = this.random ? 1 : 0;
      this.$http.post("/api/source/list", param).then((res) => {
        if (res.title) {
          this.title = res.title
        } else {
          this.title = ""
        }
        this.list = res.data;
        this.allCount = res.all_count;
        this.recordCount = res.record_count;
        this.total = res.total;
        if (this.total > 0 && this.source_index >= this.total) {
          this.source_index = this.total - 1;
          this.page = parseInt(this.source_index / 10) + 1;
          this.loading0 = false;
          this.query();
        } else {
          this.source = this.list[this.source_index % 10];
          this.loading0 = false;
        }
      })
    }
  },
};
</script>

<style scoped>
.start {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  background-color: #e6a13c;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 600;
  line-height: 38px;
  height: 40px;
  padding: 0 20px;
  width: 48px;
  text-align: center;
}

.btn-finish {
  display: inline-block;
  border: 1px #000 solid;
  border-radius: 30px;
  padding: 0 25px;
  line-height: 45px;
  height: 45px;
  font-weight: 600;
  color: #000;
}


.infomation {
  font-family: "Open Sans", sans-serif;
}



.infomation p {
  color: #282c2e;
  font-size: 16px;
  word-break: break-word;
}

.infomation p strong {
  font-weight: 700;
}

.main {
  margin: 0 10px;
}

.row>>>.el-divider--horizontal {
  margin: 20px 100px !important;
  width: auto !important;
}

.drag>>>.el-dialog__body {
  padding: 10px 15px !important;
}

.drawer>>>.el-tag--dark {
  border: none !important;
}


.Speaking>>>.el-card__header {
  color: #fff;
  background: #de7d89;
}

.Speaking>>>.el-carousel__indicators--horizontal {
  display: none;
}



.Speaking>>>.el-tag--primary {
  background: #de7d89 !important;
}

.indicator {
  width: 40px;
  height: 30px;
  line-height: 30px;
  background-color: #eee;
  text-align: center;
  margin: 0 1px;
  color: #e6a13c;
}

.indicator.on {
  background-color: #e6a13c;
  color: #fff;
}

.icon-next {
  display: inline-block;
  align-items: center;
  height: 26px;
  width: 26px;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  font-weight: 700;
  border: 1px solid #e6a13c;
  color: #e6a13c;
  font-weight: 600;
}

.icon-next i {
  font-size: 20px;
  font-weight: 700;
}

.marker {
  display: inline-block;
  margin-right: 10px;
}

.marker::after {
  display: inline-block;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #000;
  margin-bottom: 3px;
}

.pre,
.next {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
  color: #e6a13c;
  font-weight: 600;
}


.pre.disabled,
.next.disabled {
  color: #ccc;
  cursor: auto;
}

.pre.disabled i,
.next.disabled i {
  border-color: #ccc;
}

.pre.disabled i:hover,
.next.disabled i:hover {
  background-color: #fff;
  color: #ccc;
}

.pre i,
.next i {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  height: 40px;
  width: 40px;
  font-size: 30px;
  border-radius: 50%;
  border: 1px solid #e6a13c;
}

.pre i:hover,
.next i:hover {
  background-color: #e6a13c;
  color: #fff;
}

.on {
  color: #e6a13c;
  font-weight: bold;
}



.menus>>>.el-menu-item {
  padding: 0 10px !important;
}

/* .left::before {
  position: absolute;
  top: 15px;
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
  border-left: 15px solid transparent;
  left: -14px;
}


.right::before {
  position: absolute;
  top: 15px;
  content: '';
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.2);
  border-right: 15px solid transparent;
  right: -14px;
} */

.main>>>.el-carousel__arrow,
.main>>>.el-carousel__indicators--outside button {
  background-color: #E6A23C;
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}


.work>>>.el-rate__icon {
  margin-top: -2px;
  font-size: 24px !important;
}

.drawer-btn {
  height: 60px;
  width: 60px;
  background-color: #E6A23C;
  border-radius: 50%;
  position: fixed;
  right: -30px;
  top: 50%;
  margin-top: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 8888;
  transition: right 0.15s ease-in-out;
}

.drawer {
  background-color: #fff;
  padding: 0 30px;
}

.drawer h3 {
  font-size: 30px;
}

.drawer>>>.el-input__inner {
  border-radius: 20px;
  border-color: #eeeeee;
}


.drawer-btn.open {
  right: calc(65% - 30px);
  transition: right 0.25s ease-in-out;
}


.drawer-btn.open i {
  margin-left: 0;
}

.drawer-btn i {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  margin-left: -20px;
}

.info-icon {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.select {
  width: 120px;
}





.icon {
  font-size: 20px;
}

.timer {
  word-spacing: 4px;
  letter-spacing: 4px;
  font-weight: 800;
}
</style>
